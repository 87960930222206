<template>
  <div v-if="!isOnline" class="offline-bar">
    <v-icon class="mr-3">mdi-wifi-off</v-icon>
    You are currently offline
  </div>
  <main>
    <Nav v-if="isLogin" />
    <router-view />
    <LiveLocation />
  </main>
</template>

<script>
import Nav from '@/components/Nav.vue'
import LiveLocation from '@/components/LiveLocation.vue'
import { mapActions, mapState, mapStores } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
import global from '@/global'


export default {
  name: 'App',
  components: {
    Nav,
    LiveLocation,
  },
  computed: {
    ...mapStores(useAuthStore),
    ...mapState(useAuthStore, ['isLogin']),
  },
  data() {
    return {
      isOnline: navigator.onLine,
    }
  },
  created() {
    global.masterFetch();
    this.initializeNotificationPermissions()
    window.addEventListener('online', this.updateOnlineStatus)
    window.addEventListener('offline', this.updateOnlineStatus)
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus)
    window.removeEventListener('offline', this.updateOnlineStatus)
  },
  methods: {
    ...mapActions(useAuthStore, ['initializeNotificationPermissions']),
    updateOnlineStatus() {
      this.isOnline = navigator.onLine
      if (this.isOnline) {
        window.location.href = window.location.href;
      }
    }
  }
}
</script>

<style>
@import url('@/assets/css/style.css');

/** Override Toast Notification */
/**
.Toastify__progress-bar {
  background-color: var(--secondary-dark-grey) !important;
}
**/

.Toastify__toast {
  color: var(--primary-black) !important;
  border-radius: var(--border-radius) !important;
}

/**
.Toastify__progress-bar--info {
  background-color: var(--secondary-dark-grey) !important;
}
**/

.offline-bar {
  display: block;
  background-color: rgb(196, 6, 6);
  color: white;
  padding: 10px;
  text-align: center;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
}
</style>
<style src="@/assets/css/contact-number.css"></style>
