<template>
    <div class="live-location" v-if="showSideBar & isLogin">
        <div class="user-details container">
            <div>
                <div class="user">
                    <div class="image-container">
                        <v-avatar size="50" class="avatar-container">
                            <Image :imagePath="this.profile.image_path" v-if="this.profile.image_path"></Image>
                        </v-avatar>
                    </div>
                    <div class="details">
                        <h2 class="name">{{ this.profile.last_name }}</h2>
                        <p v-if="this.profile.role && this.profile.role.role_name">{{ this.profile.role.role_name }}
                        </p>
                    </div>
                    <div class="acc-status">
                        <div class="button-1" v-if="this.profile.status && this.profile.status.status_name">Status: {{
                                this.profile.status.status_name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="notes">
            <h3>Support Contact</h3>
            <a href="mailto:support@rectrixlink.com" class="button-1">support@rectrixlink.com</a>
        </div>
            
        <div class="apps">
            <h3>Apps</h3>
            <a href="https://portal.rectrixlink.com" target="_blank" class="button-3">Travel Agent portal</a>
            <a href="https://admin.rectrixlink.com" target="_blank" class="button-3">Admin Portal</a>
            <a href="https://rectrixlink.com" target="_blank" class="button-3">Landing Website</a>
        </div>

        <div class="reminders">
            <h3>Reminders</h3>
            <p>1. Please check all the documents before approving the request</p>
            <p>2. Logout of the system when not in use</p>
        </div>

        <div class="roles">
            <h3>Roles</h3>
            <p>1. Admin</p>
            <p>2. Travel Agent</p>
            <p>3. Contractor</p>
            <p>4. Driver</p>
            <p>5. Tour Guide</p>
            <p>6. Tracker</p>
        </div>
    </div>
</template>

<script>
import Image from '@/components/Image.vue';
import { mapStores, mapState } from 'pinia'
import { useSideBarStore } from '@/stores/SideBarStore'
import { useAuthStore } from '@/stores/AuthStore'

export default
    {
        name: 'LiveLocation',
        components: {
            Image
        },
        computed: {
            ...mapStores(useSideBarStore),
            ...mapStores(useAuthStore),
            ...mapState(useSideBarStore, ['showSideBar']),
            ...mapState(useAuthStore, ['isLogin', 'profile']),
        },
        data() {
            return {
            }
        },
        mounted() {
        },
        methods: {
        }
    }
</script>

<style scoped>
.apps a {
    margin-top: 10px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.button-3 {
    margin: 0px;
}

.live-location > * {
    margin-top: 40px;
}

.live-location .notes > *,
.live-location .reminders > *,
.live-location .roles > * {
    margin-top: 10px;
}

.user-details .user {
    text-align: center;
    padding: 30px;
    border-radius: var(--border-radius);
    background-color: var(--secondary-light-grey);
}

.user-details .user .acc-status {
    display: flex;
    justify-content: center;
}

.user-details .user > *:not(:first-child) {
    margin-top: 10px;
}
</style>
