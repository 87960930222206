import { defineStore } from 'pinia';
import { useAuthStore } from '@/stores/AuthStore';
import { httpPost } from '@/services/http';
import { toast } from 'vue3-toastify';
import { indexedDBService } from '@/services/indexedDBService';

export const useWebSocketStore = defineStore('websocket', {
    state: () => {
        return {
            connected: false,
            socket: null,
            socket_id: null,
            reconnectTimer: null,
            pingTimer: null,
        };
    },

    actions: {
        connect() {
            if (this.connected || this.socket?.readyState === WebSocket.CONNECTING) {
                return;
            }

            const wsUrl = 'wss://api.rectrixlink.com:6001/app/app-key';
            this.socket = new WebSocket(wsUrl);

            this.socket.onopen = () => {
                console.log('Websocket connected');
                this.connected = true;
                this.reconnectTimer = null;
                this.sendPing(); // Start the ping process once connected
            };

            this.socket.onerror = (error) => {
                this.connected = false;
                console.error('Websocket error:', error);
            };

            this.socket.onmessage = (event) => {
                const dataJSON = JSON.parse(event.data);
                this.handleWebSocketEvent(dataJSON);
            };

            this.socket.onclose = () => {
                this.connected = false;
                console.log('Websocket closed');
                this.reconnect();
            };
        },

        reconnect() {
            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
            }
            this.reconnectTimer = setTimeout(() => {
                const authStore = useAuthStore();

                if (authStore.isLogin && !this.connected) {
                    console.log('Attempting to reconnect...');
                    this.connect();
                }
            }, 10000);
        },

        async subscribeToChannel() {
            if (!this.socket || !this.socket_id) return;

            const authStore = useAuthStore();
            const websocketRequestEntity = {
                'socket_id': this.socket_id,
                'channel_name': `private-users.${authStore.profile.id}`, // Modify with your context
            };

            try {
                const result = await httpPost('/broadcasting/auth', websocketRequestEntity);
                console.log(result.data.auth);
                this.socket.send(JSON.stringify({
                    event: 'pusher:subscribe',
                    data: {
                        auth: result.data.auth,
                        channel: websocketRequestEntity['channel_name'],
                    },
                }));
            } catch (error) {
                console.error('Error:', error);
            }
        },

        sendPing() {
            if (this.pingTimer) {
                clearTimeout(this.pingTimer);
            }

            if (this.socket && this.connected) {
                console.log('Sending Ping...');
                this.socket.send(JSON.stringify({ event: 'pusher:ping', data: {} }));
            }

            this.pingTimer = setTimeout(() => {
                const authStore = useAuthStore();

                if (authStore.isLogin) {
                    if (!this.connected) {
                        console.log('Attempting to reconnect...');
                        this.connect();
                    } else {
                        this.sendPing();
                    }
                }
            }, 10000);
        },

        disconnect() {
            if (this.reconnectTimer) {
                clearTimeout(this.reconnectTimer);
            }
            if (this.pingTimer) {
                clearTimeout(this.pingTimer);
            }
            if (this.socket) {
                this.socket.close();
            }
            this.socket = null;
            this.connected = false;
            console.log('Websocket disconnected');
        },

        handleWebSocketEvent(dataJSON) {
            switch (dataJSON.event) {
                case 'pusher:connection_established':
                    console.log('Connection established');
                    this.socket_id = JSON.parse(dataJSON.data).socket_id;
                    this.subscribeToChannel();
                    break;
                case 'pusher_internal:subscription_succeeded':
                    console.log('Subscription Succeeded');
                    break;
                case 'pusher:subscription_error':
                    console.error('Subscription Error:', dataJSON);
                    break;
                case 'pusher:pong':
                    console.log('Connection Active');
                    break;
                case 'StreamOffer':
                    this.handleStreamOffer(dataJSON.data);
                    break;
                case 'StreamAnswer':
                    this.handleStreamAnswer(dataJSON.data);
                    break;
                case 'IceCandidate':
                    this.handleIceCandidate(dataJSON.data);
                    break;
                case 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated':
                    this.handleUserNotification(JSON.parse(dataJSON.data));
                    break;
                default:
                    console.log('Unhandled event:', dataJSON.event);
            }
        },

        handleStreamOffer(data) {
            console.log('StreamOffer Event:', data);
        },

        handleStreamAnswer(data) {
            console.log('StreamAnswer Event:', data);
        },

        handleIceCandidate(data) {
            console.log('IceCandidate Event:', data);
        },

        handleUserNotification(data) {
            const message = data.message;
            const jobSlug = data.type_slug;
            const relevantId = data.relevant_id;

            if (jobSlug === 'account-verification') {
                indexedDBService.getRequestsData();
            }

            toast.info(message, {
                onClick: () => {
                    if (jobSlug === 'account-verification') {
                        this.$router.push(`/requests/${relevantId}`);
                    }
                }
            });

            console.log('Notification Received');
        }
    }
});