import moment from 'moment-timezone'
import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import Toast from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';

// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

// Vuetify Phone Number Input
import 'flag-icons/css/flag-icons.min.css';
import 'v-phone-input/dist/v-phone-input.css';
import { createVPhoneInput } from 'v-phone-input';
import { VAutocomplete } from 'vuetify/components';

// Vue-lazyload.js
import VueLazyLoad from 'vue-lazyload';

// Firebase Messaging
import firebaseMessaging from '@/services/firebase'

// Workbox (Caching)
if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/sw.js')
            .then(registration => {
                console.log('ServiceWorker registration successful with scope: ', registration.scope);
            })
            .catch(error => {
                console.error('ServiceWorker registration failed: ', error);
            });
    });
}

// Detect the user's timezone
const userTimezone = moment.tz.guess();

// Set the detected timezone as the default timezone
moment.tz.setDefault(userTimezone);

console.log(`User's timezone set to: ${userTimezone}`);

const app = createApp(App);

app.config.globalProperties.$moment = moment;
app.config.globalProperties.$messaging = firebaseMessaging

const pinia = createPinia();

pinia.use(({ store }) => {
    // Allow pinia to access global variables
    store.$router = markRaw(router)
    store.$messaging = app.config.globalProperties.$messaging
});

app.component('VueDatePicker', VueDatePicker);
app.use(Toast, {
    // Default toast options
    position: "top-right",
    timeout: 5000,
    closeOnClick: true,
    clearOnUrlChange: false,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: false,
});
app.use(VueLazyLoad, {
    preLoad: 1.3,
    // error: 'path/to/error-image.jpg',
    // loading: 'path/to/loading.gif',
    attempt: 5
});

app.use(pinia)

app.use(router)
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_API
    }
})

const vuetify = createVuetify({
    components,
    directives,
})
app.use(vuetify)

// Vuetify Phone Input

app.component('VAutocomplete', VAutocomplete);

const vPhoneInput = createVPhoneInput({
    enableSearchingCountry: true,
    countryLabel: null,
    label: 'Contact Number',
    countryAriaLabel: 'Country for contact number',
    invalidMessage: 'Contact number must be a valid contact number',
    preferCountries: [
        'SG',
        'MY',
        'ID',
        'IN'
    ],
    defaultCountry: 'SG'
});

app.use(vPhoneInput);

app.mount('#app')