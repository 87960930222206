import $ from 'jquery'
import { createRouter, createWebHistory } from 'vue-router'
import { useLoadingStore } from '@/stores/LoadingStore'
import { useAuthStore } from '@/stores/AuthStore'
import { useWebSocketStore } from '@/stores/WebsocketStore';
import DashboardView from '@/views/DashboardView.vue'
import LoginView from '@/views/LoginView.vue'
import AccountInactiveView from '@/views/AccountInactiveView.vue'
import RequestDetailsView from '@/views/RequestDetailsView.vue';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
  },
  {
    path: '/account-inactive',
    name: 'AccountInactive',
    component: AccountInactiveView
  },
  {
    path: '/',
    name: 'Dashboard',
    component: DashboardView
  },
  {
    path: '/requests/:id',
    name: 'RequestDetail',
    component: RequestDetailsView
  }
]

const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const store = useLoadingStore();
  store.setLoading(true)
  const authStore = useAuthStore();
  console.log(to);
  if (authStore.isLogin) {
    // Access the WebSocket store
    const websocketStore = useWebSocketStore();

    if (!websocketStore.connected) {
      // // Connect the WebSocket when the application starts
      websocketStore.connect();
    }

    if (to.path === '/login') {
      next({ path: '/' });
    } else {
      if (authStore.profile.email_verified_at === null) {
        if (to.path !== '/email-verification') {
          next({ path: '/email-verification' });
        } else {
          next();
        }
      } else if (authStore.profile.status && authStore.profile.status.status_slug !== 'active') {
        if (to.path !== '/account-inactive') {
          next({ path: '/account-inactive' });
        } else {
          next();
        }
      } else {
        if (to.path === '/email-verification' || to.path === '/account-inactive' || to.path === '/submit-documents') {
          next({ path: '/' });
        } else {
          next();
        }
      }
    }
  } else {
    if (to.path === '/login') {
      next()
    } else {
      next({ path: '/login' })
    }
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    $('nav').css('transition', '1s all');
  }, 500)
  console.log(`Changing route from ${from.path} to ${to.path}`);
  // nextTick(() => {
  //   nav.changeColors();
  // })
});


export default router
