<template>
    <nav class="side-nav">
        <svg xmlns="http://www.w3.org/2000/svg" width="30.427" height="27.292" viewBox="0 0 30.427 27.292"
            class="nav-mobile-nav" @click="toggleNav()">
            <line id="Line_4" data-name="Line 4" x2="29.798" y2="4.365" transform="translate(5.203 2.397) rotate(40)"
                fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3"></line>
            <line id="Line_5" data-name="Line 5" y1="4.365" x2="29.798" transform="translate(28.03 5.741) rotate(140)"
                fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="3"></line>
        </svg>
        <router-link to="/" class="logo">
            <img src="@/assets/logo/Main_1.png" alt="logo" class="full-logo">
            <img src="@/assets/logo/Main_2.png" alt="logo" class="simple">
            </router-link>
        <ul>
            <div class="category">
                <p>ADMIN</p>
            </div>
            <router-link to="/" :class="{ 'active': $route.path === '/' }" title="Dashboard">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="19.072" height="19.07"
                            viewBox="0 0 19.072 19.07">
                            <g id="transfer_4_fill" transform="translate(-2.46 -2.465)">
                                <path id="Path_521" data-name="Path 521"
                                    d="M16.5,6.5A1.5,1.5,0,0,1,18,8v8.414l.974-.975a1.5,1.5,0,1,1,2.122,2.122L17.556,21.1a1.5,1.5,0,0,1-2.121,0L11.9,17.561a1.5,1.5,0,0,1,2.122-2.122l.975.975V8a1.5,1.5,0,0,1,1.5-1.5ZM8.556,2.9l3.536,3.535A1.5,1.5,0,1,1,9.97,8.561L9,7.586V16a1.5,1.5,0,0,1-3,0V7.586l-.975.975A1.5,1.5,0,1,1,2.9,6.439L6.435,2.9a1.5,1.5,0,0,1,2.121,0Z"
                                    fill="currentColor" />
                            </g>
                        </svg>

                        <span>Requests</span>
                    </div>
                </li>
            </router-link>
            <div class="category">
                <p>ACCOUNT</p>
            </div>
            <router-link to="/" title="Profile">
                <li>
                    <div class="nav-content">
                        <v-avatar size="20" class="avatar-container">
                            <Image :imagePath="this.profile.image_path" v-if="this.profile.image_path"></Image>
                        </v-avatar>

                        <span>{{ this.profile.last_name }}</span>
                    </div>
                </li>
            </router-link>
            <a href="" @click.prevent="logout()" title="Logout">
                <li>
                    <div class="nav-content">
                        <svg fill="currentColor" height="18.5px" width="18.5px" version="1.1" id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 384.971 384.971" xml:space="preserve" stroke="currentColor"
                            stroke-width="18.774565">
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                            <g id="SVGRepo_iconCarrier">
                                <g>
                                    <g id="Sign_Out">
                                        <path
                                            d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03 C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03 C192.485,366.299,187.095,360.91,180.455,360.91z">
                                        </path>
                                        <path
                                            d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279 c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179 c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z">
                                        </path>
                                    </g>
                                </g>
                            </g>
                        </svg>

                        <span>Logout</span>
                    </div>
                </li>
            </a>
            <a class="side-bar-toggle" @click.prevent="toggleSideBar()" title="Toggle Sidebar">
                <li>
                    <div class="nav-content">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.488" height="16.839"
                            viewBox="0 0 18.488 16.839">
                            <g id="Icon_akar-sidebar-right" data-name="Icon akar-sidebar-right"
                                transform="translate(-2 -3.5)">
                                <path id="Path_520" data-name="Path 520"
                                    d="M4.649,4.5H17.839a1.649,1.649,0,0,1,1.649,1.649V17.691a1.649,1.649,0,0,1-1.649,1.649H4.649A1.649,1.649,0,0,1,3,17.691V6.149A1.649,1.649,0,0,1,4.649,4.5Z"
                                    fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                    stroke-width="2" />
                                <path id="Path_521" data-name="Path 521" d="M22.5,4.5V19.339"
                                    transform="translate(-8.783)" fill="none" stroke="currentColor" stroke-width="2" />
                            </g>
                        </svg>


                        <span>Toggle Sidebar</span>
                    </div>
                </li>
            </a>
        </ul>
    </nav>
</template>

<script>
import Image from '@/components/Image.vue';
import nav from '@/assets/js/nav'
import { mapStores, mapState, mapActions } from 'pinia'
import { useSideBarStore } from '@/stores/SideBarStore'
import { useAuthStore } from '@/stores/AuthStore'
import $ from 'jquery'

export default {
    name: 'Nav',
    components: {
        Image,
    },
    computed: {
        ...mapStores(useSideBarStore, useAuthStore),
        ...mapState(useAuthStore, ['profile']),
    },
    methods: {
        toggleNav() {
            nav.toggleNav();
        },
        handleNavToggle() {
            console.log("BELOOW")
            if (window.innerWidth <= 850) {
                nav.toggleNav();
            }
        },
        ...mapActions(useSideBarStore, ['toggleSideBar']),
        ...mapActions(useAuthStore, ['logout']),
    },
    mounted() {
        $('a').on('click', this.handleNavToggle);
    },
    beforeDestroy() {
        $('a').off('click', this.handleNavToggle);
    }
}
</script>

<style scoped>
@media screen and (max-width: 1250px) {
    .side-bar-toggle {
        display: none;
    }
}

.v-avatar {
    border: 1px solid var(--secondary-dark-grey);
}
</style>