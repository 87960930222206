<template>
    <section id="content" class="home">
        <div class="main-content">
            <TopNav />
            <div class="title">
                <h1>Submited Requests</h1>
                <p>Please check the validity of the documents before approving/rejecting</p>
            </div>

            <div class="tabs">
                <!-- <div class="horizontal-nav">
                    <ul class="nav-list">
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('travel_agent')"
                                :class="{ 'button-1': currentFilter === 'travel_agent' }">Travel Agent</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('driver')"
                                :class="{ 'button-1': currentFilter === 'driver' }">Driver</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('tour-guide')"
                                :class="{ 'button-1': currentFilter === 'tour-guide' }">Tour Guide</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" @click.prevent="changeFilter('contractor')"
                                :class="{ 'button-1': currentFilter === 'contractor' }">Contractor</a>
                        </li>
                    </ul>
                </div> -->

                <div class="table-container">
                    <v-data-table v-if="data && data.length" :headers="headers"
                        :items="data" :items-per-page="-1" disable-pagination hide-default-footer>
                        <template v-slot:[`item.index`]="{ index }">
                            <span>{{ index + 1 }}.</span>
                        </template>
                        <template v-slot:[`item.company_name`]="{ item }">
                            <div class="user">
                                <div class="image-container">
                                    <Image :imagePath="item.user.imagePath"></Image>
                                </div>
                                <p>{{ item.user.firstName ?? '' + ' ' +
                            item.user.lastName }}</p>
                            </div>
                        </template>
                       <template v-slot:[`item.role`]="{ item }">
                            <span>{{ item.user.role }}</span>
                        </template>
                        <template v-slot:[`item.contact_number`]="{ item }">
                            <span>+{{ item.user.contactNumbers.find((a) => a.primary ===
                            1).countryCode }}{{
                                item.user.contactNumbers.find((a) => a.primary === 1).contactNumber }}</span>
                        </template>
                        <template v-slot:[`item.created_at`]="{ item }">
                            <span>{{ this.$moment(item.createdAt).format('DD MMM yyyy HH:mm') }}</span>
                        </template>
                        <template v-slot:[`item.action`]="{ item }">
                            <span><a href="" class="button-3"
                                @click.prevent="this.$router.push('/requests/' + item.id)">View</a></span>
                        </template>
                    </v-data-table>
                    <table class="no-data" v-else>
                        <thead>
                            <tr>
                                <td class="td-start">There are currently no data</td>
                            </tr>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import TopNav from '@/components/TopNav.vue'
import Image from '@/components/Image.vue';
import db from '@/databases/indexedDB'
import { liveQuery } from 'dexie';
import { indexedDBService } from '@/services/indexedDBService';

export default
    {
        name: 'DashboardView',
        components: {
            TopNav,
            Image
        },
        data() {
            return {
                headers: [
                    { title: 'No.', value: 'index', sortable: false },
                    { title: 'Company Name', value: 'company_name', sortable: true },
                    { title: 'Status', value: 'status', sortable: true },
                    { title: 'Role', value: 'role', sortable: true },
                    { title: 'Contact Number', value: 'contact_number', sortable: true },
                    { title: 'Request On', value: 'created_at', sortable: true },
                    { title: 'Action', value: 'action', sortable: false },
                ],
                data: [],
                currentFilter: 'travel_agent', // 'pending' or 'approved'
                subscription: null,
            }
        },
        created() {
            indexedDBService.getRequestsData()
            this.fetchData();
        },
        beforeUnmount() {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        },
        methods: {
            fetchData() {
                const observableData =
                    liveQuery(async () => {
                        const requestsData = await db.requests.orderBy('createdAt').reverse().toArray();
                        const enhancedData = await Promise.all(requestsData.map(async (request) => {
                            const user = await db.users.get({ id: request.userId });
                            const admin = request.adminId ? await db.users.get({ id: request.adminId }) : null;

                            const userContacts = await db.contact_numbers.where({ userId: user.id }).toArray();
                            const adminContacts = request.adminId ? await db.contact_numbers.where({ userId: admin.id }).toArray() : null;

                            const requestDetails = await db.details.where({ requestId: request.id }).toArray();
                            for (let detail of requestDetails) {
                                detail.type = await db.types.get({ id: detail.typeId });
                            }

                            return {
                                ...request,
                                user: {
                                    ...user,
                                    contactNumbers: userContacts,
                                },
                                admin: admin ? {
                                    ...admin,
                                    contactNumbers: adminContacts,
                                } : null,
                                details: requestDetails,
                            };
                        }));
                        return enhancedData;
                    })

                observableData.subscribe((result) => {
                    this.data = result;
                });
            },
            changeFilter(input) {
                this.currentFilter = input
            },
        }
    }
</script>

<style src="@/assets/css/index.css" scoped></style>
<style scoped>
.user .image-container {
    margin-right: 20px;
}
</style>