import { indexedDBService } from "@/services/indexedDBService";
import { useAuthStore } from "@/stores/AuthStore";

function masterFetch() {
    const authStore = useAuthStore();

    if (authStore.isLogin) {
        indexedDBService.getRequestsData();
    }
}

export default {
    masterFetch
}