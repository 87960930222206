<template>
    <div class="top-nav" ref="topNav">
        <div class="nav-toggle" @click="toggleNav()" :class="{ 'scrolled': isScrolled }">
            <svg xmlns="http://www.w3.org/2000/svg" width="28.155" height="19.436" viewBox="0 0 28.155 19.436">
                <line id="Line_1" data-name="Line 1" x2="23" transform="translate(1 1)" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-width="2" />
                <line id="Line_1-2" data-name="Line 1" x2="23" transform="translate(1 9.718)" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-width="2" />
                <line id="Line_1-3" data-name="Line 1" x2="23" transform="translate(1 18.436)" fill="none"
                    stroke="currentColor" stroke-linecap="round" stroke-width="2" />
            </svg>
        </div>
        <h3>{{ title }}</h3>
        <div class="buttons">
        </div>
    </div>
</template>

<script>
import nav from '@/assets/js/nav'

export default
    {
        name: 'TopNav',
        data() {
            return {
                title: '',
                isScrolled: false, // New property to track scroll state
            };
        },
        mounted() {
            // Add a scroll event listener when the component is mounted
            window.addEventListener('scroll', this.checkScroll);
            if (document.querySelector('.title h1').textContent != null) {
                document.title = 'Rectrix Link Admin - ' + document.querySelector('.title h1').textContent
            }
            this.checkScroll();
        },
        beforeUnmount() {
            // Remove the event listener when the component is about to be unmounted
            window.removeEventListener('scroll', this.checkScroll);
            document.title = 'Rectrix Link Admin'
        },
        methods: {
            toggleNav() {
                nav.toggleNav();
            },
            checkScroll() {
                // Access the DOM element using this.$refs and check the scroll position
                const topNav = this.$refs.topNav;
                if (window.scrollY > 0) {
                    const title = document.querySelector('.title h1')
                    this.title = title.textContent
                    topNav.classList.add('scrolled');
                } else {
                    this.title = ''
                    topNav.classList.remove('scrolled');
                }
            }
        }
    }
</script>

<style scoped>
.top-nav {
    display: flex;
    justify-content: space-between;
    z-index: 998;
    position: sticky;
    top: 0;
    /* Ensure the nav sticks to the top */
    width: 100%;
    background: white;
    padding: 10px 40px 0px 40px;
    transition: border-bottom 0.3s;
    /* Smooth transition for the border */
}

.top-nav h3 {
    display: flex;
    align-items: center;
    transition: 1s all ease-in;
}

.top-nav.scrolled {
    border-bottom: 1px solid var(--secondary-dark-grey);
}


.top-nav .buttons {
    display: flex;
    align-items: center;
    gap: 25px;

}

@media screen and (max-width: 660px) {
    .top-nav {
        padding: 10px 20px 0px 20px;
    }
}
</style>