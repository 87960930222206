<template>
    <img v-lazy="imageSrc" :alt="altText" @error="handleImageError" />
</template>

<script>
import { httpGet } from "@/services/http"; // Ensure your httpGet is properly imported

export default {
    name: 'ImageLoader',
    props: {
        imagePath: {
            type: String,
            required: true
        },
        altText: {
            type: String,
            default: 'Image'
        },
        fallbackImage: {
            type: String,
            default: '@/assets/images/logo.jpg'
        }
    },
    data() {
        return {
            imageSrc: '', // This will hold the data URL of the image
        };
    },
    watch: {
        imagePath() {
            this.loadImage();
        }
    },
    methods: {
        async loadImage() {
            try {
                const response = await httpGet(`/api/v1/administrators/profiles/image?image_path=${this.imagePath}`, {
                    responseType: 'arraybuffer'
                });
                if (response.data) {
                    this.imageSrc = `data:image/png;base64,${this.arrayBufferToBase64(response.data)}`;
                } else {
                    throw new Error('No data received');
                }
            } catch (error) {
                console.error('Failed to load image:', error);
                this.imageSrc = this.fallbackImage;
            }
        },
        arrayBufferToBase64(buffer) {
            let binary = '';
            const bytes = new Uint8Array(buffer);
            const length = bytes.byteLength;
            for (let i = 0; i < length; i++) {
                binary += String.fromCharCode(bytes[i]);
            }
            return window.btoa(binary);
        },
        handleImageError() {
            this.imageSrc = this.fallbackImage;
        }
    },
    created() {
        this.loadImage();
    }
};
</script>

<style scoped>
img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}
</style>