<template>
    <section id="content" class="home">
        <div class="main-content">

            <a href="" class="logo">
                <img src="@/assets/logo/Main_1.png" alt="logo" class="full-logo">
            </a>

            <div class="title pa-1">
                <h1>Admin Login</h1>
                <p>Note: This page is designated for administrator, if you are a travel agent please access the
                    following link</p>
                <a href="https://portal.rectrixlink.com" class="button-3">Rectrix Link Agent Portal</a>
            </div>

            <div class="login-form">
                <v-form ref="form" id="login-form" @submit.prevent="login()">

                    <div class="email-address">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Email Address" v-model="emailAddress" :rules="emailValidation"
                                placeholder="alexander_tan@gmail.com"></v-text-field>
                        </v-col>
                    </div>

                    <div class="password">
                        <v-col cols="12" class="pa-1 pb-3">
                            <v-text-field label="Password" v-model="password" :rules="passwordValidation"
                                placeholder="********" type="password"></v-text-field>
                        </v-col>
                    </div>

                    <v-col cols="12" class="pa-1 pb-3">
                        <v-btn block class="button-1" type="submit" form="login-form" :loading="isLoading">Login</v-btn>
                    </v-col>

                </v-form>
            </div>


        </div>
    </section>
</template>

<script>
import { mapActions } from 'pinia'
import { useAuthStore } from '@/stores/AuthStore'
const FormData = require('form-data');
// Toast Notification
// In any Vue component, import the toast function
import { toast } from 'vue3-toastify';
import { httpPost } from '@/services/http';
import global from '@/global'

export default {
    name: 'LoginView',
    data() {
        return {
            emailAddress: '',
            password: '',
            emailValidation: [
                v => !!v || 'Email is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
            ],
            passwordValidation: [
                v => !!v || 'Password is required',
                v => v.length >= 8 || 'Password must be at least 8 characters'
            ],
            isLoading: false,
        }
    },
    mounted() {
    },
    methods: {
        async login() {
            const isValid = await this.$refs.form.validate();

            if (isValid.valid) {
                let data = new FormData();
                data.append('grant_type', 'administrator_grant');
                data.append('type', 'password');
                data.append('client_id', process.env.VUE_APP_CLIENT_ID);
                data.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);
                data.append('email_address', this.emailAddress);
                data.append('password', this.password);
                data.append('scope', ''); // Assuming no specific scope is required

                const config = {
                    method: 'post',
                    headers: { 'Content-Type': 'multipart/form-data' }
                };
                let id = toast.loading('Please wait...');
                this.isLoading = true
                await httpPost('/oauth/token', data, id, config)
                    .then(async response => {
                        // Logic after successful login
                        const accessToken = response.data.access_token;
                        const refreshToken = response.data.refresh_token;
                        await this.setTokens(accessToken, refreshToken)
                        this.initializeNotificationPermissions(false)
                        global.masterFetch();
                        // Assuming 'router' is an instance of Vue Router passed to this function or available globally
                        toast.update(id, {
                            render: "Login successfully",
                            type: "success",
                            isLoading: false,
                            autoClose: 3000
                        });
                        this.isLoading = false
                        this.$router.push('/');
                    })
                    .catch(error => {
                        this.isLoading = false
                        console.error(error);
                    });
            }
        },
        ...mapActions(useAuthStore, ['setTokens', 'initializeNotificationPermissions'])
    }
}
</script>

<style scoped>
section#content .logo {
    width: 40%;
    margin: 0px auto;
}

section#content .logo img {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

section#content .main-content {
    max-width: 50%;
    margin: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h1 {
    text-align: center;
}

.title p {
    margin: 20px 0px;
    text-align: center;
}

@media screen and (max-width: 950px) {
    section#content .main-content {
        max-width: unset;
    }
}
</style>