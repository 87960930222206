import Dexie from 'dexie';

// Initialize the Dexie database
const db = new Dexie('rectrix-link-admin');
db.version(1).stores({
    users: '&id, firstName, lastName, emailAddress, imagePath, emailVerifiedAt, createdAt, updatedAt, deletedAt, role, status',
    contact_numbers: '&id, countryCode, contactNumber, country, primary, createdAt, updatedAt, deletedAt, userId',
    requests: '&id, status, remarks, createdAt, updatedAt, deletedAt, userId, adminId',
    details: '&id, value, filePath, originalName, createdAt, updatedAt, deletedAt, typeId, requestId',
    types: '&id, type, fieldName, proveRequired, userRoleId, createdAt, updatedAt, deletedAt',
});

export default db;